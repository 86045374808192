import React, { useEffect, useState } from 'react';
import styles from './FooterLink.module.css';
import { Link } from 'react-router-dom';
import visasdata from '../../data/singlevisas.json';
const FooterLink = () => {
    const [countries, setCountries] = useState([]);
    useEffect(() => {
        setCountries(visasdata)
    }, []);
    return (
        <div className={styles.footer}>
            <h4 className={styles.title}>Explore Visas</h4>
            <div className={styles.links}>
                {countries.map((country, index) => (
                    <Link to={"/visa/" + country.visaId} className={styles.link}>
                        {country.visaCountryName}{index < countries.length - 1 ? ' | ' : ''}
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default FooterLink;
