import { Link, useNavigate } from 'react-router-dom';
import classes from './VisaHome.module.css';
import SearchHeader from '../header/SearchHeader';
import { useEffect, useState } from 'react';
import visasdata from '../../data/singlevisas.json';
const VisaHome = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [visas, setVisas] = useState([]);
  const headerconfig = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    }
  }
  useEffect(() => {
    setVisas(visasdata)
  }, []);
  return (
    <>
      <SearchHeader></SearchHeader>
      <div className={classes.vh}>
        <ul className={classes.vh_list}>
          {visas.length > 0 && visas.map((item) => (
            <li key={item} className={classes.vh_list_item}>
              <h1 className={classes.vh_list_item_tag}>{item.visaCountryName}</h1>
              {/* <svg className={classes.vh_list_item_svg}>
                <use href="/assets/svgs/stripe.svg#heartFull"></use>
              </svg> */}
              <img
                className={classes.vh_list_item_img}
                src={"https://trawtelcdn.s3.eu-north-1.amazonaws.com/visacountries/" + item.flagSrc + ".png"}
                alt="popular" style={{ maxWidth: "400px", maxHeight: "150px" }}
              />
              <div className={classes.vh_list_brief}>
                <p className={classes.vh_list_title}>{item.visaName}</p>
                <p className={classes.vh_list_price}>INR {item.adultPrice}/-</p>
              </div>
              <ul className={classes.vh_list_item_info}>
                <li className={classes.vh_list_item_info_li}>
                  <p>Processing time</p>
                  <p>Upto {item.processingTime == 1 ? "24 hours" : item.processingTime + " days"}</p>
                </li>
                <li className={classes.vh_list_item_info_li}>
                  <p>Stay period</p>
                  <p>{item.stayPeriod}</p>
                </li>
                <li className={classes.vh_list_item_info_li}>
                  <p>Validity</p>
                  <p>{item.validity}</p>
                </li>
                <li className={classes.vh_list_item_info_li}>
                  <p>Entry</p>
                  <p>{item.entry}</p>
                </li>
              </ul>
              <Link to={"visa/" + item.visaUrl} className={classes.va_preview_btn}>View Details</Link>
            </li>
          ))}
        </ul>
        <div className="line mt-30 mb-30" />
        <div className={classes.vh_content}>
          <h2 className="text-30">Welcome to Trawtel: Your Trusted Online Visa Application Portal</h2>
          <p>At Trawtel, we simplify the visa application process, making it fast, secure, and completely hassle-free. Whether you're planning a dream vacation, a business trip, or pursuing education abroad, we're here to help you every step of the way.</p>
          <div className="row x-gap-130 y-gap-20 pt-20">
            <div className="col-lg-12">
              <div className="y-gap-15">
                <div className="d-flex"><i className="icon-check flex-center text-10 size-24 rounded-full text-green-2 bg-green-1 mr-15" /><b>Global Coverage</b>: Apply for tourist, business, or study visas to destinations worldwide.</div>
                <div className="d-flex"><i className="icon-check flex-center text-10 size-24 rounded-full text-green-2 bg-green-1 mr-15" /><b>Seamless Process</b>: Enjoy a streamlined application process with step-by-step guidance tailored to your visa type.</div>
                <div className="d-flex"><i className="icon-check flex-center text-10 size-24 rounded-full text-green-2 bg-green-1 mr-15" /><b>24/7 Support</b>: Our dedicated customer support team is available around the clock to assist you with any queries or issues.</div>
                <div className="d-flex"><i className="icon-check flex-center text-10 size-24 rounded-full text-green-2 bg-green-1 mr-15" /><b>Secure Transactions</b>: Your personal and financial information is always protected with state-of-the-art security measures.</div>
              </div>
            </div>
          </div>
          <h2 className="text-30 mt-30">How It Works</h2>
          <div className="row x-gap-130 y-gap-20 pt-20">
            <div className="col-lg-12">
              <div className="y-gap-15">
                <div className="d-flex"><i className="icon-check flex-center text-10 size-24 rounded-full text-green-2 bg-green-1 mr-15" /><b>Choose Your Visa Type</b>: Select from tourist, business, or study visas based on your travel purpose.</div>
                <div className="d-flex"><i className="icon-check flex-center text-10 size-24 rounded-full text-green-2 bg-green-1 mr-15" /><b>Submit Your Application</b>: Fill out the online form with accurate details and upload the required documents.</div>
                <div className="d-flex"><i className="icon-check flex-center text-10 size-24 rounded-full text-green-2 bg-green-1 mr-15" /><b>Track Your Progress</b>: Stay updated on your application status with real-time notifications.</div>
                <div className="d-flex"><i className="icon-check flex-center text-10 size-24 rounded-full text-green-2 bg-green-1 mr-15" /><b>Receive Your Visa</b>: Get your visa delivered promptly without any hassle.</div>
              </div>
            </div>
          </div>
          <h3 className="text-30 mt-30">Start Your Visa Journey Today!</h3>
          <p>No more delays or complicated paperwork. With Trawtel, applying for a visa has never been easier. Begin your application now and embark on your next adventure with confidence.</p>
        </div>
      </div>
    </>
  );
};
export default VisaHome;
