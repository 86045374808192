import { NavLink, useNavigate } from 'react-router-dom';
import classes from './Header.module.css';
import AutocompleteSearch from './AutocompleteSearch';
const SearchHeader = () => {
  return (
    <searchheader className={classes.header}>
      <div className={classes.header_search_title}>
      Search and Secure Your Visa on Time
      </div>
      <div className={classes.header_search_bar}>
      <AutocompleteSearch></AutocompleteSearch>
      </div>
    </searchheader>
  );
};
export default SearchHeader;
