import { useEffect, useState } from 'react';
import { UserUrls } from '../common/APIConfig';
import classes from './Profile.module.css';
import SideMenu from './SideMenu';
import axios from 'axios';
const initialFieldValues = {
  userId: "00000000-0000-0000-0000-000000000000",
  name: "",
  phoneNumber: "",
  email: ""
};
const Profile = () => {
  const [values, setValues] = useState(initialFieldValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const headerconfig = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("userToken")
    }
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (validate()) {
      const formData = {
        "userId": localStorage.getItem("userId"),
        "name": values.name,
        "email": values.email,
        "phoneNumber": values.phoneNumber,
      }
      axios.put(UserUrls.updateUser, formData, { ...headerconfig })
        .then((res) => {
          if (res.data.statusCode == 200) {
            setLoading(false);
            alert("Profile Updated")
          }
        }).catch(function (error) {
          setLoading(false);
        });
    }
    else {
      alert('check mandatory fields');
      setLoading(false);
    }
  };
  const validate = () => {
    console.log(values.passportExpireDate)
    let temp = {};
    temp.name = values.name === "" ? false : true;
    temp.phoneNumber = values.phoneNumber === "" ? false : true;
    setErrors(temp);
    return Object.values(temp).every((x) => x === true);
  };
  const getProfile = () => {
    axios.get(UserUrls.getByUserId + "/" + localStorage.getItem('userId'), { ...headerconfig })
      .then((res) => {
        console.log(res.data)
        if (res.data.data !== null) {
          setValues(res.data.data);
        }
      })
  }
  useEffect(() => {
    getProfile();
  }, []);
  return (
    <SideMenu>
      <div className={classes.profile}>
        <h1 className={classes.profile_head}>Profile</h1>
        <form onSubmit={handleSubmit} className={classes.profile_applicant_form}>
          <div className={classes.profile_applicant_inputDiv}>
            <label>Name</label>
            <input type="text" name="name" placeholder="Please enter name" value={values.name} onChange={handleInputChange}></input>
          </div>
          <div className={classes.profile_applicant_inputDiv}>
            <label>Mobile number</label>
            <input type="text" name="phoneNumber" placeholder="Please enter mobile" maxLength={13} value={values.phoneNumber} onChange={handleInputChange}></input>
          </div>
          <div className={classes.profile_applicant_inputDiv}>
            <label>Email</label>
            <input type="text" name="email" placeholder="Please enter email" value={values.email === values.phoneNumber + "@gmail.com" ? "" : values.email} onChange={handleInputChange}></input>
          </div>
          <div className={classes.profile_applicant_inputDiv}>
            <label>&nbsp;</label>
            <button className={classes.profile_applicant_saveBtn} type='submit'>Save</button>
          </div>
        </form>
      </div>
    </SideMenu>
  );
};
export default Profile;
