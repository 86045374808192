import { Outlet } from 'react-router-dom';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import ScrollToTop from '../common/ScrollToTop';
import FooterLink from '../footer/FooterLink';

const AppLayout = () => {
  return (
    <>
      <Header />
      <ScrollToTop />
      <main>
        <Outlet />
      </main>
      <FooterLink></FooterLink>
      <Footer />
    </>
  );
};
export default AppLayout;
