import { Link, useNavigate, useParams } from 'react-router-dom';
import classes from './Application.Confirmation.module.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { VisaRequestUrls } from '../common/APIConfig';
const ApplicationConfirmation = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [visaRequest, setVisaRequest] = useState({});
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const getVisaRequest = () => {
        axios.get(VisaRequestUrls.getVisaRequestsById + "/" + params.visaRequestId, { ...headerconfig })
            .then((res) => {
                if (res.data.data !== null) {
                    setVisaRequest(res.data.data);
                }
                else {
                    navigate("/")
                }
            })
    }
    useEffect(() => {
        getVisaRequest();
    }, []);
    return (
        <div className={classes.logout}>
            <img
                className={classes.logout_img}
                src="/assets/images/thumbUp.jpg"
                alt="logout"
            />
            <h4 className={classes.logout_h}>Congratulations, Application successfully submitted. Reference No : {visaRequest.visaReferenceNo}</h4>
            <Link className={classes.logout_redirect} to="/visa/applications">
                <svg className={classes.logout_searchIcon}>
                    <use href="/assets/svgs/stripe.svg#search"></use>
                </svg>
                View Applications
            </Link>
        </div>
    );
};
export default ApplicationConfirmation;
