import { useEffect, useState } from 'react';
import classes from './Applications.module.css';
import SideMenu from './SideMenu';
import axios from 'axios';
import { VisaRequestUrls } from '../common/APIConfig';
import moment from 'moment';
import { Link } from 'react-router-dom';

const Applications = () => {
  const [visaRequests, setVisaRequests] = useState([])
  const headerconfig = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    }
  }
  const getVisaRequests = () => {
    axios.get(VisaRequestUrls.getVisaRequestsByUser + "?id=" + localStorage.getItem("userId"), { ...headerconfig })
      .then((res) => {
        setVisaRequests(res.data.data);
      })
  }
  useEffect(() => {
    getVisaRequests();
  }, []);
  return (
    <SideMenu>
      <div className={classes.apps}>
        <h1 className={classes.apps_head}>All applications</h1>
        <ul className={classes.apps_list}>
          {visaRequests.length>0?
          visaRequests.map(vr=>
            <li className={classes.apps_item} key={vr.visaRequestId}>
              <h2 className={classes.apps_item_title}>{vr.visaName}</h2>
              <div className={classes.apps_item_infoDiv}>
                <p className={classes.apps_item_highlight}>
                  <strong>Status:</strong> <span>{vr.visaRequestStatus}</span>
                </p>
                <p className={classes.apps_item_info}>
                  <strong>Reference No:</strong> {vr.visaReferenceNo}
                </p>
                <p className={classes.apps_item_info}>
                  <strong>Country:</strong> {vr.countryName}
                </p>
                <p className={classes.apps_item_info}>
                  <strong>Travel Date:</strong> {moment(vr.travelDate).format('DD MMM YYYY')}
                </p>
                <p className={classes.apps_item_info}>
                  <strong>Applicants:</strong> {vr.noOfApplicants}
                </p>
                <p className={classes.apps_item_info}>
                  <strong>Total Price:</strong> {vr.totalPrice} INR
                </p>
                <h4 className={classes.apps_item_title}><Link to={"/visaapplicants/"+vr.visaRequestId}>VIEW</Link></h4>
              </div>
            </li>
          ):"No Applications"
          }
        </ul>
      </div>
    </SideMenu>
  );
};
export default Applications;
