import React, { useState } from 'react';
import styles from './Search.module.css';
import jsonData from '../../data/singlevisas.json'; // Import your JSON file
import { useNavigate } from 'react-router-dom';

const AutocompleteSearch = () => {
    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState('');
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    const handleInputChange = (e) => {
        const query = e.target.value;
        setInputValue(query);

        if (query.length > 0) {
            const filtered = jsonData.filter((item) =>
                item.visaCountryName.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredSuggestions(filtered);
            setIsDropdownVisible(true);
        } else {
            setFilteredSuggestions([]);
            setIsDropdownVisible(false);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setInputValue(suggestion.visaCountryName);
        setIsDropdownVisible(false);
        navigate('/visa/' + suggestion.visaUrl);
    };

    return (
        <div className={styles.searchContainer}>
            <div className={styles.inputWrapper}>
                <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Search by country..."
                    className={styles.input}
                />
                <span className={styles.searchIcon}>
                    <svg className={styles.header_searchIcon}>
                        <use href="/assets/svgs/stripe.svg#search"></use>
                    </svg>
                </span>
            </div>
            {isDropdownVisible && (
                <ul className={styles.suggestionsList}>
                    {filteredSuggestions.map((suggestion) => (
                        <li
                            key={suggestion.visaUrl}
                            className={styles.suggestionItem}
                            onClick={() => handleSuggestionClick(suggestion)}
                        >
                            <span className={styles.flag}>
                                <img
                                    src={`https://flagcdn.com/w40/${suggestion.flagSrc.toLowerCase()}.png`}
                                    alt={suggestion.visaCountryName}
                                    className={styles.flagImage}
                                />
                            </span>
                            {suggestion.visaCountryName}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default AutocompleteSearch;
