import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { B2CServiceFee, VisaRequestUrls } from '../common/APIConfig';
import visaData from "../../data/b2cvisas.json";
import moment from 'moment';
import { Helmet } from "react-helmet-async";
import LoginSignup from '../auth/LoginSignup';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from 'react-share';
const Visa = () => {
    const params = useParams();
    const navigate = useNavigate();
    const today = new Date();
    const futureDate = new Date(today);
    const [visaId, setVisaId] = useState("00000000-0000-0000-0000-000000000000")
    const [visa, setVisa] = useState({})
    const [visas, setVisas] = useState({})
    const [visaCountry, setVisaCountry] = useState({})
    const [visaDocuments, setVisaDocuments] = useState([])
    const [visaTerms, setVisaTerms] = useState([])
    const [visaFAQ, setVisaFAQ] = useState([])
    const shareUrl = window.location;
    const title = `${visa?.visaName} Visa for ${visaCountry?.name} | Trawtel`;
    const description = `Apply for a ${visa?.visaName} Visa for ${visaCountry?.name} online. Quick processing, hassle-free application, and 24/7 customer support.`;
    const keywords = `${visa?.visaName} visa, ${visa?.visaType} visa for ${visaCountry?.name}, online visa application for ${visaCountry?.name}, ${visa?.visaType} visa services, ${visaCountry?.name} visa`;
    const [loading, setLoading] = useState(false);
    const [showLogin, setShowlogin] = useState(false);
    const loginToggle = () => setShowlogin(!showLogin);
    function getCountryByVisaId() {
        if (!params?.visaUrl) {
            console.error("No visaUrl provided in params");
            return;
        }
        // Find the country that contains the visaId in its visas array
        const matchingCountry = visaData.find((country) =>
            country.visas?.some((visa) => visa.visaUrl === params.visaUrl)
        );

        if (matchingCountry) {
            setVisaCountry(matchingCountry);
            setVisas(matchingCountry.visas);
            const matchingVisa = matchingCountry.visas.find((v) => v.visaUrl === params.visaUrl)
            setVisa(matchingVisa)
            setVisaId(matchingVisa?.visaId)
            setVisaDocuments(matchingCountry.visaDocuments)
            setVisaFAQ(matchingCountry.visaFAQs)
            setVisaTerms(matchingCountry.visaTerms)
        } else {
            console.warn("No country found for visaUrl:", params.visaUrl);
        }
    };
    const CreateVisaRequest = () => {
        const formData = new FormData()
        formData.append("visaRequestId", "00000000-0000-0000-0000-000000000000")
        formData.append("visaId", visaId)
        formData.append("leadSource", "B2C")
        formData.append("visaName", visa.visaName)
        formData.append("countryName", visaCountry.name)
        formData.append("pricePerApplicant", parseFloat(visa.adultPrice))
        formData.append("netPrice", parseFloat(visa.adultPrice))
        formData.append("serviceFee", parseFloat(B2CServiceFee))
        formData.append("gst", 0)
        formData.append("totalPrice", parseFloat(visa.adultPrice) + parseFloat(B2CServiceFee))
        formData.append("travelDate", moment(new Date()).format('YYYY-MM-DD'))
        formData.append("expectedDate", moment(futureDate.setDate(today.getDate() + parseInt(visa?.processingTime + 1, 10))).format('YYYY-MM-DD'))
        formData.append("noOfApplicants", 1)
        formData.append("userId", localStorage.getItem('userId'))
        formData.append("email", localStorage.getItem('email'))
        formData.append("mobile", localStorage.getItem('mobile'))
        formData.append("visaRequestStatus", "PENDING")
        formData.append("paymentStatus", "PENDING")
        axios.post(VisaRequestUrls.createVisaRequest, formData, { ...headerconfig })
            .then((res) => {
                navigate('/visarequest/' + res.data.data);
                setLoading(false);
            }).catch(function (error) {
                setLoading(false);
            });
    }
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const handleLoginSuccess = () => {
        CreateVisaRequest(); // Call API after successful login
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (localStorage.getItem('userId') !== null) {
            setShowlogin(false)
            CreateVisaRequest();
        }
        else {
            setShowlogin(true);
        }
    };
    useEffect(() => {
        getCountryByVisaId();
    }, [params.visaUrl]);
    return (
        <div>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
            </Helmet>
            {showLogin && <LoginSignup toggleView={loginToggle} onLoginSuccess={handleLoginSuccess} />}
            {visa ? (
                <div>
                    <section className="py-30 mt-20">
                        <div className="container">
                            <div className="row y-gap-20 justify-between items-end">
                                <div className="col-auto">
                                    <h2 className="text-30 sm:text-30 lh-14">
                                        {visa.visaName}
                                    </h2>
                                    <h2 className="text-20 sm:text-20 lh-14">
                                        {visaCountry.name}
                                    </h2>
                                    <div className="row x-gap-20 y-gap-20 items-center pt-20">
                                        <div className="col-auto">
                                            <div className="d-flex items-center">
                                                <div className="d-flex x-gap-5 pr-10">
                                                    <i className="flex-center icon-star text-yellow-2 text-12" />
                                                    <i className="flex-center icon-star text-yellow-2 text-12" />
                                                    <i className="flex-center icon-star text-yellow-2 text-12" />
                                                    <i className="flex-center icon-star text-yellow-2 text-12" />
                                                    <i className="flex-center icon-star text-yellow-2 text-12" />
                                                </div>
                                                4.8 (269)
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="d-flex items-center">
                                                <i className="icon-reservation text-16 mr-5" />
                                                10K+ applied
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="d-flex items-center">
                                                <i className="icon-reservation text-16 mr-5" />
                                                Get Visa in {visa?.processingTime + 1} Days
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="d-flex x-gap-30 y-gap-10">
                                        <div className="d-flex items-center">
                                            <p style={{ marginRight: "10px" }}>Share</p>
                                            <WhatsappShareButton url={shareUrl} title={title} style={{ marginRight: "10px" }}>
                                                <WhatsappIcon size={32} round />
                                            </WhatsappShareButton>
                                            <TwitterShareButton url={shareUrl} title={title} style={{ marginRight: "10px" }}>
                                                <TwitterIcon size={32} round />
                                            </TwitterShareButton>
                                            <FacebookShareButton url={shareUrl} quote={title}>
                                                <FacebookIcon size={32} round />
                                            </FacebookShareButton>
                                        </div>
                                        {/* <a href="#" className="d-flex items-center">
                                            <i className="icon-heart flex-center text-16 mr-10" />
                                            Wishlist
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="js-pin-container">
                        <div className="container">
                            <div className="row y-gap-30 justify-between">
                                <div className="col-lg-8">
                                    <div data-anim-child="delay-2" className="tourSingleGrid -type-1 mt-0">
                                        <div className="tourSingleGrid__grid mobile-css-slider-2">
                                            <img src={"https://trawtelcdn.s3.eu-north-1.amazonaws.com/visacountries/" + visaCountry.flagSrc + ".png"} alt={visaCountry.url} />
                                        </div>
                                    </div>
                                    <div className="row y-gap-20 justify-between items-center layout-pb-md mt-10">
                                        <div className="col-lg-3 col-6">
                                            <div className="d-flex items-center">
                                                <div className="flex-center size-50 rounded-12 border-1">
                                                    <i className="text-20 icon-clock" />
                                                </div>
                                                <div className="ml-10">
                                                    <div className="lh-16">Visa Type</div>
                                                    <div className="text-14 text-light-2 lh-16">{visa.visaType}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-6">
                                            <div className="d-flex items-center">
                                                <div className="flex-center size-50 rounded-12 border-1">
                                                    <i className="text-20 icon-clock" />
                                                </div>
                                                <div className="ml-10">
                                                    <div className="lh-16">Length of the stay</div>
                                                    <div className="text-14 text-light-2 lh-16">{visa.stayPeriod}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-6">
                                            <div className="d-flex items-center">
                                                <div className="flex-center size-50 rounded-12 border-1">
                                                    <i className="text-20 icon-clock" />
                                                </div>
                                                <div className="ml-10">
                                                    <div className="lh-16">Validity</div>
                                                    <div className="text-14 text-light-2 lh-16">{visa.validity}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-6">
                                            <div className="d-flex items-center">
                                                <div className="flex-center size-50 rounded-12 border-1">
                                                    <i className="text-20 icon-teamwork" />
                                                </div>
                                                <div className="ml-10">
                                                    <div className="lh-16">Entry</div>
                                                    <div className="text-14 text-light-2 lh-16">{visa.entry}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line mt-0 mb-10" />
                                    <h2 className="text-30">Process</h2>
                                    <div className="mt-10">
                                        <div className="roadmap -roadmap">
                                            <div className="roadmap__item">
                                                <div className="roadmap__icon">
                                                </div>
                                                <div className="roadmap__wrap">
                                                    <div className="d-flex items-center justify-between">
                                                        <div className="roadmap__title">Step 1 : Apply on Trawtel</div>
                                                        <p></p>
                                                    </div>
                                                    <div className="active">
                                                        <div className="roadmap__content">
                                                            Submit your documents and pay the amount
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="roadmap__item">
                                                <div className="roadmap__icon" />
                                                <div className="roadmap__wrap">
                                                    <div className="d-flex items-center justify-between">
                                                        <div className="roadmap__title">Step 2 : Documents will be verified</div>
                                                    </div>
                                                    <div className="active">
                                                        <div className="roadmap__content">
                                                            Our team will verify the documents and submit to immigration
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="roadmap__item">
                                                <div className="roadmap__icon" />
                                                <div className="roadmap__wrap">
                                                    <div className="d-flex items-center justify-between">
                                                        <div className="roadmap__title">Step 3 : Processing Visa</div>
                                                    </div>
                                                    <div className="active">
                                                        <div className="roadmap__content">
                                                            Our team will work with immigration to get your visa.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="roadmap__item">
                                                <div className="roadmap__icon" />
                                                <div className="roadmap__wrap">
                                                    <div className="d-flex items-center justify-between">
                                                        <div className="roadmap__title">Step 4 : Receive Your Visa</div>
                                                    </div>
                                                    <div className="active">
                                                        <div className="roadmap__content">
                                                        Once the visa process is successfully completed, we will notify you and deliver your visa promptly.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line mt-30 mb-30" />
                                    <h2 className="text-30">Documents Required</h2>
                                    <div className="row x-gap-130 y-gap-20 pt-20">
                                        <div className="col-lg-12">
                                            <div className="y-gap-15">
                                                {visaDocuments.length > 0 && visaDocuments.map(vd =>
                                                    <div className="d-flex">
                                                        <i className="icon-check flex-center text-10 size-24 rounded-full text-green-2 bg-green-1 mr-15" />
                                                        {vd.documentName}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line mt-30 mb-30" />
                                    <h2 className="text-30">FAQ</h2>
                                    <div className="-simple row y-gap-20 mt-30">
                                        {visaFAQ.length > 0 && visaFAQ.map((vd, index) =>
                                            <div className="col-12">
                                                <div className="px-20 py-15 border-1 rounded-12">
                                                    <div className="accordion__button d-flex items-center justify-between">
                                                        <div className="button text-16 text-dark-1">{vd.question}</div>
                                                    </div>
                                                    <div className="">
                                                        <div className="pt-20">
                                                            <p>{vd.answer}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="line mt-30 mb-30" />
                                    <h2 className="text-30">Terms</h2>
                                    <div className="row x-gap-130 y-gap-20 pt-20">
                                        <div className="col-lg-12">
                                            <div className="y-gap-15">
                                                {visaTerms.length > 0 && visaTerms.map(vd =>
                                                    <div className="d-flex">
                                                        <i className="icon-check flex-center text-10 size-24 rounded-full text-green-2 bg-green-1 mr-15" />
                                                        {vd.termsandConditions}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                        <div className="d-flex justify-end js-pin-content">
                                            <div className="tourSingleSidebar">
                                                <div class="sidebar -type-1 rounded-12">
                                                    <div class="sidebar__header bg-accent-1">
                                                        <div class="text-18 text-dark fw-500">Price Summary</div>
                                                    </div>
                                                </div>
                                                <div className="line mt-20 mb-20" />
                                                <div className="d-flex items-center justify-between" style={{ padding: "10px" }}>
                                                    <div className="text-18 fw-500">Visa Fee:</div>
                                                    <div className="text-18 fw-500">INR {visa.adultPrice}</div>
                                                </div>
                                                <div className="d-flex items-center justify-between" style={{ padding: "10px" }}>
                                                    <div className="text-18 fw-500">Service Fee:</div>
                                                    <div className="text-18 fw-500">INR {B2CServiceFee}</div>
                                                </div>
                                                <div className="d-flex items-center justify-between" style={{ padding: "10px" }}>
                                                    <div className="text-18 fw-500">Total:</div>
                                                    <div className="text-18 fw-500">INR {parseFloat(visa.adultPrice) + parseFloat(B2CServiceFee)}</div>
                                                </div>
                                                {loading === false ?
                                                    <button className="button -md -dark-1 col-12 bg-accent-1 text-dark mt-20" type="submit">
                                                        Apply Now
                                                        <i className="icon-arrow-top-right ml-10" />
                                                    </button>
                                                    :
                                                    <button className="button -md -dark-1 col-12 bg-accent-1 text-dark mt-20" type="button" disabled>
                                                        Loading...
                                                        <i className="icon-arrow-top-right ml-10" />
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="layout-pt-xl layout-pb-xl">
                        <div className="container">
                            <div className="row">
                                <div className="col-auto">
                                    <h2 className="text-30">Check other visas from {visaCountry.name}</h2>
                                </div>
                            </div>
                            <div className="row y-gap-30 pt-40 sm:pt-20">
                                {visas.length > 0 && visas.map(i =>
                                    <div className="col-lg-3 col-md-6">
                                        <Link to={"/visa/" + i.visaUrl} className="tourCard -type-1 d-block border-1 bg-white hover-shadow-1 overflow-hidden rounded-12 bg-white -hover-shadow">
                                            <div className="tourCard__content px-20 py-10">
                                                <div className="tourCard__location d-flex items-center text-13 text-light-2">
                                                    <i className="icon-pin d-flex text-16 text-light-2 mr-5" />
                                                    {visaCountry.name}
                                                </div>
                                                <h3 className="tourCard__title text-16 fw-500 mt-5">
                                                    <span>{i.visaName}</span>
                                                </h3>
                                                <div className="tourCard__rating d-flex items-center text-13 mt-5">
                                                    <div className="d-flex x-gap-5">
                                                        <span className="text-dark-2">{i.visaType}</span>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-between items-center border-1-top text-13 text-dark-1 pt-10 mt-10">
                                                    <div className="d-flex items-center">
                                                        <i className="icon-clock text-16 mr-5" />
                                                        Get in {i.processingTime} days
                                                    </div>
                                                    <div>From <span className="text-16 fw-500">INR {i.adultPrice}</span></div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>
                </div>)
                : (
                    <p>Visa not found!</p>
                )}
        </div>
    );
};

export default Visa;
