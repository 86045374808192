import { useState } from 'react';
import classes from './Footer.module.css';
import { Link } from 'react-router-dom';
import { BsFacebook,BsTwitter,BsInstagram } from "react-icons/bs";
const Footer = () => {
  const [toggleInput, setToggleInput] = useState(false);

  const inputToggle = () => setToggleInput(!toggleInput);

  return (
    <div className={classes.footer}>
      <div className={classes.footer_help}>
        <svg className={classes.footer_help_svg} onClick={inputToggle}>
          <use href="/assets/svgs/stripe.svg#call_fill"></use>
        </svg>
        {toggleInput && (
          <div className={classes.footer_help_data}>
            <h1 className={classes.footer_help_data_h}>Need Assistance?</h1>
            <ul className={classes.footer_help_data_ul}>
              <li>
                <p>Email:</p>
                <p>help@trawtel.com</p>
              </li>
              <li>
                <p>Call:</p>
                <p>+91 9876543210</p>
              </li>
              <p className={classes.footer_help_data_msg}>
                09:00AM - 09:00PM (working hours)
              </p>
            </ul>

            <p className={classes.footer_help_data_msg2}>
              Feel free to connect with us. Make enquiries or get assistance.
            </p>
          </div>
        )}
      </div>
      <div className={classes.footer_section}>
        <div className={classes.footer_col1}>
          <div className={classes.footer_col1_row1}>
            <p className={classes.footer_logo}><Link to={"/"} style={{ fontSize: "30px", fontWeight: "bold", paddingTop: "10px"}}>trawtel.com</Link></p>
            <p className={classes.footer_col1_row2}>
          Trawtel is your one-stop online visa application portal, offering fast, secure, and hassle-free visa services. Apply for tourist, business, and study visas worldwide with step-by-step guidance and 24/7 support.
          </p>
          </div>          
          <div className={classes.footer_col1_row3}>          
            <Link to="https://instagram.com/trawtel" target='_blank'>
            <BsInstagram color='#FDE047' className={classes.footer_social} />
            </Link>
            <Link to="https://www.facebook.com/trawtel/" target='_blank'>
            <BsFacebook color='#FDE047' className={classes.footer_social} />
            </Link>
            <Link to="https://twitter.com/trawtel" target='_blank'>
            <BsTwitter color='#FDE047' className={classes.footer_social} />
            </Link>
          </div>
        </div>
        <div className={classes.footer_col2}>
          <h4 className={classes.footer_link_h}>Company</h4>
          <div className={classes.footer_link_list}>            
            <Link to="/about">About Us</Link>
          </div>
        </div>
        <div className={classes.footer_col3}>
          <h4 className={classes.footer_link_h}>Support</h4>
          <div className={classes.footer_link_list}>
            <Link>Help Center</Link>
            <Link to="/contact">Contact</Link>
          </div>
        </div>
        <div className={classes.footer_col4}>
          <h4 className={classes.footer_link_h}>Legal</h4>
          <div className={classes.footer_link_list}>
            <Link to="/privacy">Privacy</Link>
            <Link>Terms</Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
