export const B2CServiceFee = 499;
export const B2CGST = 18;
const ServerAPI = 'https://trawtelvisa.azurewebsites.net';
const LocalAPI = 'https://localhost:7231';
const ActiveAPI = ServerAPI;

export const VisaUrls = {
  topVisas: `${ActiveAPI}/api/visa/gettop`,
  searchVisa: `${ActiveAPI}/api/visa/getall`,
  getVisaByCountryURL: `${ActiveAPI}/api/visa/getbycountry?countryurl=`,
  getVisaByCountryId: `${ActiveAPI}/api/visa/getbycountryid`,
  getVisaById: `${ActiveAPI}/api/visa/`
};
export const VisaRequestUrls = {
  createVisaRequest: `${ActiveAPI}/api/visaRequest/Create`,
  updateVisaRequest: `${ActiveAPI}/api/visaRequest`,
  getVisaRequestsByUser: `${ActiveAPI}/api/visaRequest/GetByUserId`,
  getVisaRequestsById: `${ActiveAPI}/api/visaRequest`,
};
export const VisaApplicantUrls = {
  createVisaApplicant: `${ActiveAPI}/api/VisaApplicant/Create`,
  getVisaApplicantsByRequest: `${ActiveAPI}/api/VisaApplicant/GetByVisaRequest`
};
export const HomeUrls = {
  sendotp: `${ActiveAPI}/api/home/userotp`,
  verifyotp: `${ActiveAPI}/api/home/verifyotp`,
};
export const PaymentUrls = {
  PAYMENTREQUESTORDER: `${ActiveAPI}/api/Payment/Process`
};
export const UserUrls = {
  getByUserId: `${ActiveAPI}/api/user`,
  updateUser: `${ActiveAPI}/api/user`,
};
